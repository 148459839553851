import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isDrupalSessionActive } from '../helpers/isDrupalSessionActive';

export const DrupalEditContent = ({ id }) => {
  const [state, setState] = useState({ uuid: null });

  useEffect(() => {
    if (id) {
      const isNativePreview =
        window.location.pathname.startsWith('/node/preview');
      const uuid = isNativePreview
        ? window.location.pathname.split('/')[3]
        : null;
      setState({
        uuid
      });
    }
  }, [id]);

  if (isDrupalSessionActive()) {
    const editStyles = {
      position: 'fixed',
      backgroundColor: '#fc0',
      display: 'block',
      bottom: '0px',
      color: '#000',
      zIndex: '99999',
      padding: '10px 20px',
      right: '140px',
      fontFamily: 'GeoEditRegular',
      fontWeight: 400
    };
    const contentListStyles = { ...editStyles, right: '4px' };

    let url = `/user/login?destination=/node/${id}/edit`;
    let label = 'Edit';
    if (state.uuid) {
      url += `?uuid=${state.uuid}`;
      label = '〈 Back to edit';
    }

    return (
      <>
        <a href={url} style={editStyles}>
          {label}
        </a>
        <a
          href="/user/login?destination=/admin/content"
          style={contentListStyles}
        >
          Content list
        </a>
      </>
    );
  }
  return null;
};

DrupalEditContent.propTypes = {
  id: PropTypes.string.isRequired
};
